@import './responsive.scss';
@import './vars.scss';

// Helpers
@mixin center($vertical: false, $horizontal: false) {
  position: absolute;
  @if $vertical and $horizontal {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  } @else if $vertical {
    top: 50%;
    transform: translateY(-50%);
  } @else if $horizontal {
    left: 50%;
    transform: translateX(-50%);
  }
}

// UI styles
@mixin shadow() {
  box-shadow: 0 2.8px 2.9px rgba(0, 0, 0, 0.02),
    0 6.7px 7px rgba(0, 0, 0, 0.028), 0 12.5px 13.1px rgba(0, 0, 0, 0.035),
    0 22.3px 23.5px rgba(0, 0, 0, 0.042), 0 41.8px 43.9px rgba(0, 0, 0, 0.05),
    0 100px 105px rgba(0, 0, 0, 0.07);
}

// Scrollbar mixin
@mixin scrollbars(
  $size,
  $foreground-color,
  $background-color: mix($foreground-color, white, 50%)
) {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: $size;
    height: $size;
  }

  &::-webkit-scrollbar-thumb {
    background: $foreground-color;
    border-radius: 0.5rem;
  }

  &::-webkit-scrollbar-track {
    background: $background-color;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: $foreground-color;
    scrollbar-track-color: $background-color;
  }
}
