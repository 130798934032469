@import 'styles/mixins.scss';

.center {
  width: 100%;
  height: 100%;
}

.centerX {
  @include center($horizontal: true);
}
.centerY {
  @include center($vertical: true);
}
.centerXY {
  @include center($horizontal: true, $vertical: true);
}
